<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="form">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="form.examStatus"
            placeholder="全部"
            @change="handleCurrentChange(1)"
          >
            <el-option label="全部" value></el-option>
            <el-option label="正在考试" value="3"></el-option>
            <el-option label="考试完成" value="4"></el-option>
            <el-option label="等待补考" value="5"></el-option>
            <el-option label="正在补考" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阅卷状态" class="formItemBoxStyle">
          <el-select
            v-model="form.examMarking"
            placeholder="全部"
            @change="handleCurrentChange(1)"
          >
            <el-option label="全部" value></el-option>
            <el-option label="等待阅卷" value="1"></el-option>
            <el-option label="正在阅卷" value="2"></el-option>
            <el-option label="阅卷完成" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试科目" class="formItemBoxStyle">
          <el-select
            v-model="form.subjectType"
            placeholder="全部"
            @change="handleCurrentChange(1)"
          >
            <el-option label="全部" value></el-option>
            <el-option label="物理" value="1"></el-option>
            <el-option label="生物" value="2"></el-option>
            <el-option label="化学" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称" class="formItemBoxStyle">
          <!-- @blur="init"
          @keyup.enter.native="init"-->
          <el-input
            v-model="form.examName"
            placeholder="请输入考试名称"
            @change="handleCurrentChange(1)"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-throttle type="primary" @click="scoreQuery"
            >查询</el-button
          >
          <el-button v-throttle @click="onEmpty">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="multipleTable"
        :data="scoreList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="examName"
          label="考试名称"
          min-width="200px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="考试状态" show-overflow-tooltip width="200px">
          <template slot-scope="scope">
            <span
              :class="scope.row.examStatus === 3 ? 'bluePoint' : 'greyPoint'"
            ></span
            >&nbsp;
            <span>{{ scope.row.examStatus | examStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="阅卷状态" width="200px" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.markStatus | markingStatus
          }}</template>
        </el-table-column>
        <el-table-column label="考试科目" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.subjectTypeList | subjectTypeFilter
          }}</template>
        </el-table-column>
        <el-table-column label="开始时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.examStartTime != 0">{{
              scope.row.examStartTime | dateFormat
            }}</span>
            <span v-else>未开始</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.examEndTime != 0">{{
              scope.row.examEndTime | dateFormat
            }}</span>
            <span v-else>未结束</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon
              @click="seeInfo(scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon
              @click="exportInfo(scope.row)"
              :disabled="scope.row.exportStatus == false"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { isEmpty } from '@/utils/util.js'
import { queryScoreList } from '@/api/score/scoreQuery.js'
export default {
  name: 'examIndex',
  data() {
    return {
      form: {
        examName: '',
        examStatus: '',
        examMarking: '',
        subjectType: '',
      },
      scoreList: [],
      //分页
      page: {
        pageIndex: 1,
        pagSize: 10,
        totalSize: 0,
      },
      loading: false,
    }
  },
  filters: {
    //考试状态过滤
    examStatus(val) {
      switch (val) {
        case 2:
          return `等待考试`
        case 3:
          return `正在考试`
        case 4:
          return `考试完成`
        case 5:
          return `等待补考`
        case 6:
          return `正在补考`
        case 7:
          return `考试终止`
      }
    },
    //阅卷状态过滤
    markingStatus(val) {
      switch (val) {
        case 1:
          return `等待阅卷`
        case 2:
          return `正在阅卷`
        case 3:
          return `阅卷完成`
      }
    },
    //学科过滤
    subjectTypeFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const subjectMap = {
        1: '物理',
        2: '生物',
        3: '化学',
      }
      return val.map((item) => subjectMap[item] || `错误的值${item}`).join(',')
    },
    //时间过滤
    dateFormat(val) {
      const dt = new Date(val)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      //padStart(2,'0') 意思是不足两位自动补0
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
  },

  mounted() {
    this.scoreQuery()
  },

  methods: {
    //点击查看按钮
    seeInfo(row) {
      console.log(row, 'ROW')
      if (isEmpty(row)) {
        return
      }

      //跳转子路由并传参
      // this.$router.push({ path: '/score/scoreInfo', query: { id: row.state } });
      this.$router.push({
        name: 'scoreInfo',
        query: { examId: row.examId, subject: row.subjectTypeList },
      })
    },

    handleSelectionChange() {},
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.scoreQuery()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      this.page.pagSize = val
      this.scoreQuery()
    },

    //成绩列表查询
    scoreQuery() {
      if (this.form.examStatus != '') {
        var examStatus = parseInt(this.form.examStatus)
      } else {
        var examStatus = ''
      }
      if (this.form.examMarking != '') {
        var markStatus = parseInt(this.form.examMarking)
      } else {
        var markStatus = ''
      }
      if (this.form.subjectType != '') {
        var subjectType = parseInt(this.form.subjectType)
      } else {
        var subjectType = ''
      }
      let param = {
        examName: this.form.examName,
        examStatus: examStatus,
        markStatus: markStatus,
        subjectType: subjectType,
      }
      this.loading = true
      queryScoreList(this.page.pageIndex, this.page.pagSize, param).then(
        (res) => {
          this.loading = false
          let { success, data } = res
          this.scoreList = data
          this.page.totalSize = res.total
          console.log(data, success)
        }
      )
    },
    onEmpty() {
      this.form = {
        examName: '',
        examStatus: '',
        examMarking: '',
        subjectType: '',
      }
      this.scoreQuery()
    },
  },
}
</script>

<style scoped>
.bluePoint {
  width: 15px;
  height: 15px;
  border: 1px solid #1890ff;
  border-radius: 100px;
  background: #1890ff;
  opacity: 0.5;
  display: inline-block;
  vertical-align: middle;
}
.greyPoint {
  width: 15px;
  height: 15px;
  border: 1px solid #909399;
  border-radius: 100px;
  background: #909399;
  opacity: 0.5;
  display: inline-block;
  vertical-align: middle;
}
</style>
